import "../Styles/Feedbacks.css";
import image from "../Assets/Feedbacks/Mask group.png";
import star from "../Assets/Feedbacks/star.svg";
import gis from "../Assets/Feedbacks/2gis.svg";
import dlink from "../Assets/Feedbacks/darklink.svg";
import mob from "../Assets/Feedbacks/sdfsdf.png";

function FeedCard({ text, name }) {
  return (
    <div className="feedcard">
      <div className="stars">
        <img src={star}></img>
        <img src={star}></img>
        <img src={star}></img>
        <img src={star}></img>
        <img src={star}></img>
      </div>
      <div className="text">{text}</div>
      <div className="names">
        <img src={gis}></img>
        <div>
          <div className="name">{name}</div>
          <div className="gistext">Отзыв из 2ГИС</div>
        </div>
      </div>
      <a href="https://2gis.kz/astana/geo/70000001018104949">
        <img src={dlink} className="link"></img>
      </a>
    </div>
  );
}

function Feedbacks({ updateForm }) {
  function form() {
    updateForm();
  }

  return (
    <div className="feedbacks" id="FEED">
      <div className="box">
        <div className="ffll">
          <div className="flex">
            <div className="left">
              <div className="mobile-left">
                <img src={mob} className="mob-png"></img>
                <div className="mb1">Отзывы Наших Довольных Клиентов</div>
                <div className="mb2">
                  Наши клиенты ценят профессиональный подход и успешные
                  результаты. Каждый отзыв — это история решения сложных задач и
                  защиты прав. Оставьте свою проблему, и мы найдем для нее
                  лучшее решение.
                </div>
              </div>
              <div className="mask">
                <div className="m1">Отзывы Наших Довольных Клиентов</div>
                <div className="m2">
                  Наши клиенты ценят{" "}
                  <span>профессиональный подход и успешные результаты.</span>{" "}
                  Каждый отзыв — это история решения{" "}
                  <span>сложных задач и защиты прав.</span> Оставьте свою
                  проблему, и мы найдем для нее лучшее решение.
                </div>
                <img src={image}></img>
              </div>
            </div>
            <div className="right">
              <FeedCard
                text={
                  "1. Ихсанғали Абзал Махамбетұлы — высококлассный специалист с огромным опытом в области права. Он грамотно и профессионально вел наше дело, помог разобраться в сложной ситуации и добился справедливого решения. Его внимание к деталям и способность предлагать оптимальные решения просто восхищают. Рекомендую этого юриста всем, кто ищет надежного и квалифицированного профессионала!"
                }
                name={"Алихан Смаилов"}
              ></FeedCard>
              <FeedCard
                text={
                  "Работа с Ихсанғали Абзал Махамбетұлы была настоящим удовольствием. Его высокий профессионализм и компетентность помогли нам выиграть дело, в котором ситуация казалась безнадежной. Он всегда был на связи, консультировал по всем вопросам и сопровождал на каждом этапе процесса. Чувствовалось, что наш успех для него был так же важен, как и для нас самих. Благодарны за его работу!"
                }
                name={"Айгуль Жанабердиева"}
              ></FeedCard>
              <FeedCard
                text={
                  "Ихсанғали Абзал Махамбетұлы — это юрист, которому можно доверять. Он не только глубоко разбирается в юридических вопросах, но и заботится о своих клиентах. Благодаря его профессиональной работе, наш спор был решен в кратчайшие сроки и с минимальными затратами. Я очень доволен результатом и рекомендую его всем, кто нуждается в квалифицированной юридической помощи!"
                }
                name={"Кайрат Садыков"}
              ></FeedCard>
              <FeedCard
                text={
                  "Я обратился к Ихсанғали Абзал Махамбетұлы по рекомендации друзей, и не разочаровался. Он подошел к делу с максимальной ответственностью, был внимателен к каждому нюансу и быстро нашел выход из сложной правовой ситуации. Его советы и стратегии были четкими и продуманными, благодаря чему мы добились отличного результата. Спасибо за отличную работу!"
                }
                name={"Ерлан Тлегенов"}
              ></FeedCard>
              <FeedCard
                text={
                  "Работа с Ихсанғали Абзал Махамбетұлы оставила только положительные впечатления. Он всегда находит время для детального обсуждения всех моментов, разъясняет сложные юридические вопросы простым и понятным языком. Его опыт и знания помогли мне выиграть дело и избежать многих проблем. Это юрист, который действительно борется за права своих клиентов"
                }
                name={"Диана Нурмухамедова"}
              ></FeedCard>
              <FeedCard
                text={
                  "Обратился к Абзалу Махамбетұлы с вопросом по гражданскому праву. Очень профессиональный и внимательный юрист, который помог мне разобраться в сложной ситуации. Его консультация была исчерпывающей, а подход — вдумчивым и эффективным. Благодаря его помощи, я смог успешно защитить свои интересы в суде. Рекомендую всем, кто ищет надежного и квалифицированного юриста!"
                }
                name={"Асмас Исмагулов"}
              ></FeedCard>
            </div>
          </div>
          <div className="footer">
            <div className="f1">Помогаем в Решении Трудных Ситуаций</div>
            <button onClick={form}>Кнопка связи</button>
          </div>
        </div>

        <div className="connect">
          <div className="c1">Помогаем в Решении Трудных Ситуаций</div>
          <button onClick={form}>Кнопка связи</button>
        </div>
      </div>
    </div>
  );
}

export default Feedbacks;
