import { useState } from "react";
import "../../Styles/Cards.css";
import exit from "../../Assets/Cards/Group 446.svg";
export default function Card2({ open, close }) {
  const op = () => {
    open();
  };

  const cl = () => {
    close();
  };
  return (
    <div className="card-serv card2">
      <div className="exit" onClick={cl}>
        <img src={exit}></img>
      </div>
      <div className="cddd1">
        <div className="cd1">
          Консультации по гражданским и другим правоотношениям
        </div>
      </div>
      <div className="cd2">
        Мы предоставляем профессиональные консультации по гражданским и другим
        правоотношениям, чтобы помочь клиентам разобраться в сложных юридических
        вопросах. Наши эксперты анализируют ситуацию, предоставляют подробные
        разъяснения по актуальным вопросам и предлагают стратегии для решения
        проблем. Мы работаем с различными аспектами гражданского права, включая
        договорные обязательства, имущественные споры, наследственные дела и
        семейные вопросы. Наша цель — обеспечить клиентов необходимой правовой
        информацией и поддержкой на каждом этапе.
      </div>
      <ul>
        <li>
          <div>Анализ договоров и рекомендации по их правовым аспектам.</div>
        </li>
        <li>
          <div>
            Консультации по вопросам семейного права, включая разводы и
            алименты.
          </div>
        </li>
        <li>
          <div>Консультации по вопросам гражданского права и обязательств.</div>
        </li>
        <li>
          <div>Разъяснения по вопросам наследственного права.</div>
        </li>
        <li>
          <div>Помощь в решении имущественных споров и взыскании долгов.</div>
        </li>
        <li>
          <div>
            Подготовка правовых заключений по конкретным ситуациям и документам.
          </div>
        </li>
      </ul>
      <button onClick={op}>Обратиться</button>
    </div>
  );
}
