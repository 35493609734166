import { useState } from "react";
import "../Styles/Form.css";
import { TailSpin } from "react-loader-spinner";

export default function Form({ setOpen }) {
  const [formData, setFormData] = useState({
    name: "", 
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState('initial')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatus('pending')
    fetch("submit.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          setStatus('fail')
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        setStatus('success')
        setFormData({
          name: "",
          phone: "",
          message: "",
        });
        setTimeout(() => {
          setOpen(false)
          setStatus('initial')
        }, 1500)
      })
      .catch((error) => {
        setStatus('fail')
        setTimeout(() => {
          setStatus('initial')
        }, 1500)
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="form">
      <div className="left">
        <div>ABZAL IKH</div>
      </div>
      <div className="right">
        <form onSubmit={handleSubmit}>
          <div className="f1 r1">Ваше имя</div>
          <input
            class="name-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={formData.name ? "" : ""} // Clear placeholder when typing
            required
          />
          <div className="f1">Номер мобильного телефона</div>
          <input
            class="num-input"
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder={"+7(___) ___-__-__"}
            required
          />
          <div className="f1">Город</div>
          <input
            class="city-input"
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder={formData.message ? "" : ""}
            required
          />
          {
            status === 'pending'? 
            <div className="spinner">
              <TailSpin
                visible={true}
                height="30"
                width="30"
                color="#000"
                ariaLabel="tail-spin-loading"
                radius="2"
              />
            </div>:
            <button 
              type="submit"
              style={{
                background: status === 'fail'? '#F95651': 
                            status === 'success'? '#60CF60': 
                            '#fed544'
              }}
              disabled={status === 'pending'}
            >{
              status === 'success'? 'Отправлено!':
              status === 'fail'? 'Ошибка, повторите попытку':
              'Отправить'
            }</button>
          }
        </form>
      </div>
    </div>
  );
}
