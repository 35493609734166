import { useState } from "react";
import "../../Styles/Cards.css";
import exit from "../../Assets/Cards/Group 446.svg";

export default function Card1({ open, close }) {
  const op = () => {
    open();
  };

  const cl = () => {
    close();
  };

  return (
    <div className="card-serv card1">
      <div className="exit" onClick={cl}>
        <img src={exit}></img>
      </div>
      <div className="cddd1">
        <div className="cd1">
          Взыскание задолженности (по распискам, договорам займа, обращение
          взыскания на имущество и т.д.)
        </div>
      </div>
      <div className="cd2">
        Мы предоставляем услуги по взысканию задолженности, включая суммы по
        распискам, договорам займа и обращение взыскания на имущество. Наша
        команда юристов помогает клиентам на всех этапах процесса взыскания,
        начиная с анализа долга и подготовки документов и заканчивая
        представлением интересов в суде. Мы обеспечиваем индивидуальный подход к
        каждому делу, разрабатываем стратегии для эффективного взыскания и
        максимально защищаем интересы клиента.
      </div>
      <ul>
        <li>
          <div>
            Консультации по вопросам взыскания задолженности и оценка перспектив
            дела.
          </div>
        </li>
        <li>
          <div>Подготовка и отправка досудебных требований должникам.</div>
        </li>
        <li>
          <div>Подготовка исковых заявлений для обращения в суд.</div>
        </li>
        <li>
          <div>
            Представление интересов клиента в суде по делам о взыскании долгов.
          </div>
        </li>
        <li>
          <div>
            Обращение взыскания на имущество должника (арест, продажа и т.д.).
          </div>
        </li>
        <li>
          <div>
            Сопровождение исполнительного производства и взаимодействие с
            судебными приставами.
          </div>
        </li>
      </ul>
      <button onClick={op}>Обратиться</button>
    </div>
  );
}
