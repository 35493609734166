import "../Styles/Main.css";
import icon from "../Assets/Main/icon.svg";
import Navbar from "./Navbar";
import { Link } from "react-scroll";

function Main({ updateForm }) {
  function form() {
    updateForm();
  }

  return (
    <div className="main" id="MAIN">
      <div className="content">
        <img src={icon} className="icon"></img>
        <div className="number">
          <a href="https://wa.me/77056626538">+7 705 662 6538</a>
        </div>
        <Navbar></Navbar>

        <div className="m1">Ваш Надежный Юридический консультант</div>
        <div className="m2">Абзал Махамбетұлы Ихсанғали</div>
        <div className="m3">
          Юридический консультант с обширным опытом в судебных разбирательствах
          и гражданском праве
        </div>
        <div className="m4">
          <button onClick={form}>Запишитесь на консультацию</button>
          <Link
            to="SERVICE"
            spy={true}
            smooth={true}
            offset={30}
            duration={700}
          >
            <button className="b2">Смотреть услуги</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Main;
