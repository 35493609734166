import { useState } from "react";
import "../../Styles/Cards.css";
import exit from "../../Assets/Cards/Group 446.svg";
export default function Card5({ open, close }) {
  const op = () => {
    open();
  };

  const cl = () => {
    close();
  };
  return (
    <div className="card-serv card5">
      <div className="exit" onClick={cl}>
        <img src={exit}></img>
      </div>
      <div className="cddd1">
        <div className="cd1">
          Обжалование действий банков и частных судебных исполнителей
        </div>
      </div>
      <div className="cd2">
        Мы оказываем юридическую помощь в обжаловании действий банков и частных
        судебных исполнителей. Наши юристы анализируют ситуацию, выявляют
        нарушения и готовят обоснованные жалобы в суд или государственные
        органы. Мы сопровождаем клиента на всех этапах, начиная с подачи
        заявления и заканчивая представлением интересов в суде, при
        необходимости – в апелляционных инстанциях. Процесс включает сбор
        доказательств, подготовку необходимых документов и стратегию защиты.
        Специализируемся на спорах, связанных с незаконным взысканием долгов,
        блокировкой счетов и неправомерными действиями исполнителей, защищая
        интересы клиентов перед банками и государственными структурами.
      </div>
      <ul>
        <li>
          <div>
            Консультации по вопросам обжалования действий банков и судебных
            исполнителей.
          </div>
        </li>
        <li>
          <div>
            Подготовка и подача жалоб на действия кредитных организаций.
          </div>
        </li>
        <li>
          <div>Представительство в суде при обжаловании решений банков.</div>
        </li>
        <li>
          <div>
            Сопровождение клиентов в процессе обжалования действий судебных
            исполнителей.
          </div>
        </li>
        <li>
          <div>Подготовка и подача апелляционных жалоб на решения судов.</div>
        </li>
        <li>
          <div>
            Сбор доказательств и документов для обоснования жалоб и защиты
            интересов клиента.
          </div>
        </li>
      </ul>
      <button onClick={op}>Обратиться</button>
    </div>
  );
}
