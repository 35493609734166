import "../Styles/Navbar.css";
import { Link } from "react-scroll";
import React, { useState } from "react";

export default function Navbar() {
  const [active, setActive] = useState(false);

  const click = () => {
    setActive(!active);
  };

  return (
    <>
      <div className="navbar">
        <ul>
          <li>
            <Link to="MAIN" spy={true} smooth={true} offset={0} duration={1000}>
              Главная
            </Link>
          </li>
          <li>
            <Link to="ME" spy={true} smooth={true} offset={-30} duration={1000}>
              Обо Мне
            </Link>
          </li>
          <li>
            <Link
              to="SERVICE"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Услуги
            </Link>
          </li>
          <li>
            <Link
              to="FEED"
              spy={true}
              smooth={true}
              offset={20}
              duration={1000}
            >
              Отзывы
            </Link>
          </li>
          <li>
            <Link to="CONT" spy={true} smooth={true} offset={0} duration={1000}>
              Контакты
            </Link>
          </li>
        </ul>
      </div>

      <div className={active ? "mobile-navbar active" : "mobile-navbar"}>
        <div className={active ? "triple active" : "triple"} onClick={click}>
          <div className="t1"></div>
          <div className="t2"></div>
          <div className="t3"></div>
        </div>
        <ul className={active ? "active" : ""}>
          <li>
            <Link to="MAIN" spy={true} smooth={true} offset={0} duration={1000}>
              Главная
            </Link>
          </li>
          <li>
            <Link to="ME" spy={true} smooth={true} offset={-30} duration={1000}>
              Обо Мне
            </Link>
          </li>
          <li>
            <Link
              to="SERVICE"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Услуги
            </Link>
          </li>
          <li>
            <Link
              to="FEED"
              spy={true}
              smooth={true}
              offset={20}
              duration={1000}
            >
              Отзывы
            </Link>
          </li>
          <li>
            <Link to="CONT" spy={true} smooth={true} offset={0} duration={1000}>
              Контакты
            </Link>
          </li>

          <li>
            <a href="https://wa.me/77056626538">+7 705 662 6538</a>
          </li>
        </ul>
      </div>
    </>
  );
}
