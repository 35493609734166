import { useState } from "react";
import "../../Styles/Cards.css";
import exit from "../../Assets/Cards/Group 446.svg";
export default function Card4({ open, close }) {
  const op = () => {
    open();
  };

  const cl = () => {
    close();
  };
  return (
    <div className="card-serv card3">
      <div className="exit" onClick={cl}>
        <img src={exit}></img>
      </div>
      <div className="cddd1">
        <div className="cd1">Исполнительные производства</div>
      </div>
      <div className="cd2">
        Мы предоставляем услуги по сопровождению исполнительных производств,
        обеспечивая защиту прав и законных интересов клиентов. Наша команда
        юристов поможет вам разобраться в процессе исполнительного производства,
        начиная с анализа вашего дела и подготовки необходимых документов,
        заканчивая представлением ваших интересов в судебных органах. Мы
        занимаемся вопросами, связанными с взысканием долгов, спорами по
        исполнительным листам и действиями судебных приставов. Наша цель —
        добиться справедливости и защиты ваших прав на каждом этапе.
      </div>
      <ul>
        <li>
          <div>Консультации по вопросам исполнительного производства.</div>
        </li>
        <li>
          <div>
            Подготовка и подача заявлений и ходатайств в рамках исполнительного
            производства.
          </div>
        </li>
        <li>
          <div>
            Представительство интересов клиента в судебных органах при
            оспаривании действий судебных исполнителей.
          </div>
        </li>
        <li>
          <div>Обжалование решений и действий судебных приставов.</div>
        </li>
        <li>
          <div>
            Подготовка апелляционных и кассационных жалоб по делам об
            исполнительном производстве.
          </div>
        </li>
        <li>
          <div>
            Сопровождение взыскания долгов и исполнение судебных решений.
          </div>
        </li>
      </ul>
      <button onClick={op}>Обратиться</button>
    </div>
  );
}
