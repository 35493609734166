import "../Styles/Spheres.css";
import img1 from "../Assets/Spheres/img1.svg";
import img2 from "../Assets/Spheres/img2.svg";
import img3 from "../Assets/Spheres/img3.svg";
import img4 from "../Assets/Spheres/img4.svg";
import img5 from "../Assets/Spheres/img5.svg";

function Card({ image, head, text }) {
  return (
    <div className="card">
      <img src={image}></img>
      <div className="head">{head}</div>
      <div className="text">{text}</div>
    </div>
  );
}

function Spheres({ updateForm }) {
  function form() {
    updateForm();
  }

  return (
    <div className="spheres">
      <div className="s1">Сферы Деятельности</div>
      <div className="s2">
        Оказываем полный спектр юридических услуг в ключевых областях права. Мы
        помогаем клиентам эффективно решать правовые вопросы на всех этапах — от
        до судебного регулирования спора и до полного сопровождение в судебном
        заседании. А так при положительном решении суда в пользу доверителя
        контроль исполнения решения суда. Наш многолетний опыт и глубокие знания
        законодательства гарантируют качественное решение ваших задач.
      </div>
      <div className="container">
        <Card
          image={img1}
          head={"Взыскание задолженности:"}
          text={
            "Комплексный подход к вопросам взыскания долгов по распискам, договорам и другим документам."
          }
        ></Card>
        <Card
          image={img2}
          head={"Исполнительные производства:"}
          text={"Реализация судебных решений и взыскание задолженностей."}
        ></Card>
        <Card
          image={img3}
          head={"Гражданское право:"}
          text={"Комплексная правовая помощь по гражданским делам."}
        ></Card>
        <Card
          image={img4}
          head={"Взыскание задолженности:"}
          text={
            "Комплексный подход к вопросам взыскания долгов по распискам, договорам и другим документам."
          }
        ></Card>
        <Card
          image={img5}
          head={"Судебные разбирательства:"}
          text={"Представление интересов клиентов в суде."}
        ></Card>
      </div>

      <button onClick={form}>Запишитесь на консультацию</button>
    </div>
  );
}

export default Spheres;
