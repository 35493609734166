import "./App.css";
import Main from "./Components/Main";
import AboutMe from "./Components/AboutMe";
import Spheres from "./Components/Spheres";
import Services from "./Components/Services";
import Feedbacks from "./Components/Feedbacks";
import Contacts from "./Components/Contacts";
import Form from "./Components/Form";
import Card1 from "./Components/Cards/Card1";
import Card2 from "./Components/Cards/Card2";
import Card3 from "./Components/Cards/Card4";
import Card4 from "./Components/Cards/Card3";
import Card5 from "./Components/Cards/Card5";

import React, { useState, useEffect, useRef } from "react";

function App() {
  // Submit Form
  const [form, setForm] = useState(false);
  const [cardNum, setCardNum] = useState(0);

  // Create separate refs for form and each card
  const formRef = useRef(null);
  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);
  const card4Ref = useRef(null);
  const card5Ref = useRef(null);

  const updateForm = () => {
    setForm(true);
  };

  const updateCard = (int) => {
    setCardNum(int);
  };

  const open = () => {
    setForm(true);
    setCardNum(0);
  };

  const close = () => {
    setCardNum(0);
  };

  // Clicking outside of pop-up window
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close form if clicked outside
    if (formRef.current && !formRef.current.contains(event.target)) {
      setForm(false);
    }

    // Close card if clicked outside
    if (
      card1Ref.current &&
      !card1Ref.current.contains(event.target) &&
      card2Ref.current &&
      !card2Ref.current.contains(event.target) &&
      card3Ref.current &&
      !card3Ref.current.contains(event.target) &&
      card4Ref.current &&
      !card4Ref.current.contains(event.target) &&
      card5Ref.current &&
      !card5Ref.current.contains(event.target)
    ) {
      setCardNum(0); // Close the card if clicked outside of any card
    }
  };

  return (
    <>
      <div className={form ? "form-open" : "form-close"} ref={formRef}>
        <Form setOpen={setForm}></Form>
      </div>
      <div
        className={cardNum === 1 ? "card-open" : "card-close"}
        ref={card1Ref}
      >
        <Card1 open={open} close={close}></Card1>
      </div>
      <div
        className={cardNum === 2 ? "card-open" : "card-close"}
        ref={card2Ref}
      >
        <Card2 open={open} close={close}></Card2>
      </div>
      <div
        className={cardNum === 3 ? "card-open" : "card-close"}
        ref={card3Ref}
      >
        <Card4 open={open} close={close}></Card4>
      </div>
      <div
        className={cardNum === 4 ? "card-open" : "card-close"}
        ref={card4Ref}
      >
        <Card3 open={open} close={close}></Card3>
      </div>
      <div
        className={cardNum === 5 ? "card-open" : "card-close"}
        ref={card5Ref}
      >
        <Card5 open={open} close={close}></Card5>
      </div>
      <div className={form || cardNum != 0 ? "blur-whole" : ""}>
        <Main updateForm={updateForm}></Main>
        <AboutMe updateForm={updateForm}></AboutMe>
        <Spheres updateForm={updateForm}></Spheres>
        <Services updateForm={updateForm} updateCard={updateCard}></Services>
        <Feedbacks updateForm={updateForm}></Feedbacks>
        <Contacts></Contacts>
      </div>
    </>
  );
}

export default App;
