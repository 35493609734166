import "../Styles/AboutMe.css";
import img from "../Assets/AboutMe/WhatsApp Image 2024-10-04 at 09.57.17 2.png";
import check from "../Assets/AboutMe/Group 22.svg";

function AboutMe({ updateForm }) {
  function form() {
    updateForm();
  }

  return (
    <div className="about-me" id="ME">
      <div className="content">
        <div className="flex">
          <div className="left">
            <div className="a1">Обо Мне</div>
            <div className="a2">Абзал Махамбетұлы Ихсанғали</div>
            <div className="a3">
              Высококвалифицированный юрист с более чем 20-летним опытом.
              Родился 4 сентября 1980 года, окончил Актюбинский юридический
              колледж МВД РК и Западно-Казахстанский государственный университет
              им. М. Утемисова. За годы работы в различных государственных и
              частных структурах, Абзал приобрел уникальный опыт в юриспруденции
              и правоохранительной деятельности.
            </div>
            <div className="a4">Мой профессиональный путь</div>
            <div className="a5">
              <img src={check}></img>
              <div className="text">
                Сентябрь 1999 – Сентябрь 2008:{" "}
                <span>Органы дознания и следствия</span>
              </div>
            </div>
            <div className="a6">
              <img src={check}></img>
              <div className="text">
                Более 20 лет работы в юриспруденции и правопорядке, начиная с
                органа дознания до{" "}
                <span>
                  частного судебного исполнителя и юридического консультанта.
                </span>
              </div>
            </div>
            <button className="a7" onClick={form}>
              Запишитесь на консультацию
            </button>
          </div>
          <div className="right">
            <img src={img}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
