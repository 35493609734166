import { useState } from "react";
import "../Styles/Contact.css";
import whats from "../Assets/Contacts/Vector-1.svg";
import tlg from "../Assets/Contacts/Vector-2.svg";
import email from "../Assets/Contacts/Vector.svg";
import { TailSpin } from "react-loader-spinner";

function Contacts() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [status, setStatus] = useState('initial')

  const submit = (e) => {
    e.preventDefault();

    setStatus('pending')
    fetch("submit.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        phone,
        message
      }),
    })
    .then((response) => {
      if (!response.ok) {
        setStatus('fail')
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(() => {
      setStatus('success')
      setName('');
      setMessage('');
      setPhone('');
      setTimeout(() => {
        setStatus('initial')
      }, 1500)
    })
    .catch((error) => {
      setStatus('fail')
      setTimeout(() => {
        setStatus('initial')
      }, 1500)
      console.error("Error submitting form:", error);
    });
  };

  return (
    <div className="contacts" id="CONT">
      <div className="cont">
        <div className="flex">
          <form className="left" onSubmit={submit}>
            <div className="l1">Свяжитесь с нами</div>
            <div className="l2">Отправьте нам запрос</div>

            <div className="hhhjj">
              <div className="flexx">
                <div>
                  <div className="l3">Ваше имя</div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <div className="l3">Номер Телефона</div>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="+7-(___) - (__) - (__)"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="decr">
                <div className="l3">Опишите проблему</div>
                <textarea
                  id="message"
                  name="message"
                  className="desc"
                  placeholder="У меня возникли проблемы с взысканием долга по расписке....."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            {
              status === 'pending'? 
              <div className="spinner-contact">
                <TailSpin
                  visible={true}
                  height="30"
                  width="30"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                />
              </div>:
              <button 
                className="submit"
                type="submit"
                style={{
                  background: status === 'fail'? '#F95651': 
                              status === 'success'? '#60CF60': 
                              'transparent'
                }}
                disabled={status === 'pending'}
              >{
                status === 'success'? 'Отправлено!':
                status === 'fail'? 'Ошибка!':
                'Отправить'
              }</button>
            }
          </form>

          <div className="right">
            <div className="r1">
              Не откладывайте решение правовых вопросов. Наши эксперты готовы
              предоставить консультацию и помочь вам в кратчайшие сроки.
              Выберите удобный способ связи и получите квалифицированную помощь
              прямо сейчас.
            </div>
            <div className="infos">
              <div className="info bott">
                <a href="https://wa.me/77056626538">
                  <img src={whats} alt="WhatsApp"></img>
                </a>
                <div>
                  <div className="i1">
                    <a href="https://wa.me/77056626538">WhatsApp</a>
                  </div>
                  <div className="i2">
                    <a href="https://wa.me/77056626538">+7 705 662 6538</a>
                  </div>
                </div>
              </div>
              <div className="info bott">
                <img src={email} alt="Email"></img>
                <div>
                  <div className="i1">
                    <a>Почта</a>
                  </div>
                  <div className="i2">
                    <a>Abzal127@gmail.com</a>
                  </div>
                </div>
              </div>
              <div className="info">
                <a href="https://tttttt.me/AbzalIkhsan">
                  <img src={tlg} alt="Telegram"></img>
                </a>

                <div>
                  <div className="i1">
                    <a href="https://tttttt.me/AbzalIkhsan">Telegram</a>
                  </div>
                  <div className="i2">
                    <a href="https://tttttt.me/AbzalIkhsan">@AbzalIkhsan</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
