import { useState } from "react";
import "../../Styles/Cards.css";
import exit from "../../Assets/Cards/Group 446.svg";
export default function Card3({ open, close }) {
  const op = () => {
    open();
  };

  const cl = () => {
    close();
  };
  return (
    <div className="card-serv card4">
      <div className="exit" onClick={cl}>
        <img src={exit}></img>
      </div>
      <div className="cddd1">
        <div className="cd1">
          Процессуальное сопровождение в судебных процессах
        </div>
      </div>
      <div className="cd2">
        Мы предоставляем полное процессуальное сопровождение в судебных делах,
        начиная с анализа ситуации и консультации, заканчивая исполнением или
        обжалованием решения. Юрист изучает обстоятельства, собирает документы и
        разрабатывает стратегию. После подготовки искового заявления или отзыва,
        мы представляем интересы клиента в суде, участвуем в заседаниях,
        отстаиваем позицию и предоставляем доказательства. При вынесении решения
        мы обеспечиваем его исполнение, взаимодействуя с судебными приставами,
        или готовим апелляционные жалобы для обжалования. Мы специализируемся на
        имущественных, трудовых, семейных спорах, административных делах и
        защите прав потребителей.
      </div>
      <ul>
        <li>
          <div>
            Споры по имущественным вопросам (например, взыскание долгов,
            взыскание посредников по интернет-мошенничеству или компенсация
            убытков).
          </div>
        </li>
        <li>
          <div>
            Трудовые споры, в том числе незаконное увольнение и восстановление
            на работе.
          </div>
        </li>
        <li>
          <div>
            Семейные споры, включая разводы, раздел имущества, алименты.
          </div>
        </li>
        <li>
          <div>
            Административные дела, связанные с оспариванием действий
            государственных органов или должностных лиц.
          </div>
        </li>
        <li>
          <div>Дела по защите прав потребителей.</div>
        </li>
      </ul>
      <button onClick={op}>Обратиться</button>
    </div>
  );
}
