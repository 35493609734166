import React, { useState, useEffect } from "react";

import "../Styles/Services.css";
import img1 from "../Assets/Services/image 84.png";
import img2 from "../Assets/Services/image 81.png";
import img3 from "../Assets/Services/image 77.png";
import img4 from "../Assets/Services/image 80.png";
import img5 from "../Assets/Services/image 83.png";
import left from "../Assets/Services/left.png";
import right from "../Assets/Services/right.png";

const cards = [
  {
    image: img1,
    head: "Взыскание задолженности (по распискам, договорам займа, обращение взыскания на имущество и т.д.)",
    text: "Эффективно взыщем задолженности, работая в рамках закона. Мы обеспечим возврат ваших средств, независимо от сложности ситуации, с использованием всех доступных правовых инструментов.",
    val: 0,
  },
  {
    image: img2,
    head: "Консультации по гражданским и другим правоотношениям",
    text: "Получите профессиональную консультацию по любым правовым вопросам. Наши юристы помогут вам разобраться в сложных ситуациях и предложат оптимальные решения, обеспечивая защиту ваших интересов и прав.",
    val: 1,
  },
  {
    image: img3,
    head: "Процессуальное сопровождение в судебных процессах",
    text: "Мы предоставляем полное сопровождение на каждом этапе судебного процесса. Защитим ваши интересы в суде и обеспечим грамотную и эффективную юридическую поддержку для достижения положительного результата.",
    val: 2,
  },
  {
    image: img4,
    head: "Исполнительные производства",
    text: "Помогаем успешно завершить исполнительное производство и реализовать судебные решения. Обеспечиваем оперативное взыскание задолженностей и защиту ваших прав на стадии исполнения.",
    val: 3,
  },
  {
    image: img5,
    head: "Обжалование действий банков и частных судебных исполнителей",
    text: "Защитите свои права от неправомерных действий банков и судебных исполнителей. Мы подготовим и подадим жалобы, добиваясь справедливости и восстановления ваших прав.",
    val: 4,
  },
];

function Card({ image, head, text, isActive, formUp, cardUp, number }) {
  function form() {
    formUp();
  }

  function click() {
    cardUp(number);
  }

  return (
    <div className={`card ${isActive ? "active" : ""}`}>
      <img src={image}></img>
      <div className="head">{head}</div>
      <div className="text">{text}</div>
      <div className="buttons">
        <button onClick={form}>Обратиться</button>
        <button className="b2" onClick={click}>
          Подробнее
        </button>
      </div>
    </div>
  );
}

function Services({ updateForm, updateCard }) {
  const [currentIndex, setCurrentIndex] = useState(2);
  const [three, setThree] = useState(2);
  const [photosPerSlide, setPhotosPerSlide] = useState(5);
  const [visibleCards, setVisibleCards] = useState([]);

  // Update photosPerSlide based on window size
  const updatePhotosPerSlide = () => {
    if (window.innerWidth <= 675) {
      setPhotosPerSlide(1); // Mobile view
      setVisibleCards(cards.slice(currentIndex, currentIndex + 1));
    } else if (window.innerWidth <= 1270) {
      setPhotosPerSlide(3); // Tablet view
      setVisibleCards(cards.slice(1, 4));
    } else {
      setPhotosPerSlide(5); // Desktop view
      setVisibleCards(cards);
    }
  };

  useEffect(() => {
    updatePhotosPerSlide(); // Check on initial render
    window.addEventListener("resize", updatePhotosPerSlide); // Add event listener for window resize
    return () => window.removeEventListener("resize", updatePhotosPerSlide); // Cleanup on unmount
  }, []);

  function next() {
    if (currentIndex < 4) {
      setCurrentIndex(currentIndex + 1);

      if (photosPerSlide === 3) {
        if (three < 3) {
          setThree(three + 1);
        } else {
          setVisibleCards(cards.slice(currentIndex - 1, currentIndex + 2));
        }
      }

      if (photosPerSlide === 1) {
        setVisibleCards(cards.slice(currentIndex + 1, currentIndex + 2));
      }
    }
  }

  function prev() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      if (photosPerSlide === 3) {
        if (three > 1) {
          setThree(three - 1);
        } else {
          setVisibleCards(cards.slice(currentIndex - 1, currentIndex + 2));
        }
      }

      if (photosPerSlide === 1) {
        setVisibleCards(cards.slice(currentIndex - 1, currentIndex));
      }
    }
  }

  function form() {
    updateForm();
  }

  function cardUp(num) {
    updateCard(num);
  }

  return (
    <div className="services" id="SERVICE">
      <div className="se1">Наши услуги</div>
      <div className="se2">
        Ваш Надежный Партнер в Решении Юридических Вопросов
      </div>
      <div className="se3">
        Предоставляем полный спектр юридических услуг – от консультации до
        полного сопровождения в суде. Защитите свои права и интересы с помощью
        наших опытных юристов. Мы работаем для вашего успеха и спокойствия
      </div>
      <div className="container">
        {visibleCards.map((card, index) => (
          <Card
            image={card.image}
            head={card.head}
            text={card.text}
            isActive={card.val === currentIndex ? true : false} // Make the middle card active
            formUp={form}
            cardUp={cardUp}
            number={card.val + 1}
          />
        ))}
      </div>

      <div className="btns">
        <button onClick={prev}>
          <img src={left}></img>
        </button>
        <div className="indicators">
          <div
            className={currentIndex === 0 ? "indicator active" : "indicator"}
          ></div>
          <div
            className={currentIndex === 1 ? "indicator active" : "indicator"}
          ></div>
          <div
            className={currentIndex === 2 ? "indicator active" : "indicator"}
          ></div>
          <div
            className={currentIndex === 3 ? "indicator active" : "indicator"}
          ></div>
          <div
            className={currentIndex === 4 ? "indicator active" : "indicator"}
          ></div>
        </div>
        <button onClick={next}>
          <img src={right}></img>
        </button>
      </div>
    </div>
  );
}

export default Services;
